
































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import { getConfigEnv, getComponent } from '@/utils/helpers';

import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
	  TasqItem: () => getComponent('tasqs/TasqItem'),
  },
})
export default class PadItem extends Vue {
  @Prop({ type: String, required: false }) tasqId?: string;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

  wellListExpanded = false

  showAllWells = true

  MAX_WELL_COUNT = 20

  showRelatedWells = false;

  defermentUnits = 'bpd'

  get getPadDefermentUnit() {
    if (this.showRelatedWells) {
	  let localDefermentUnits = 'bpd';
	  for (let x = 0; x < this.padWells.length; x++) {
		  if (this.padWells[x].defermentValue != undefined && !this.padWells[x].completed) {
			  localDefermentUnits = this.padWells[x].defermentUnit;
        //   total_deferment_count = total_deferment_count + this.padWells[x].defermentValue
			  // @ts-ignore
		  }
	  }
	  this.defermentUnits = localDefermentUnits;
	 if (getConfigEnv('OPERATOR_LOWERCASED') === 'swn' && this.defermentUnits === 'bpd') {
		 return '';
	 }
	  return `${this.defermentUnits} total`;
    }
    return '';
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  getPadName(name) {
	  return this.toTitleCase(name.replaceAll('_', ' '));
  }

  allWellListToggle() {
	  if (this.showAllWells) {
		  return 'Show producing';
		  }
		  return 'Show producing';
  }

  get predictionTypeWell() {
    if (this.tasq && this.tasq.wells.length && this.tasq.wells.find((well) => well.predictionType !== 'Producing')) {
      return this.padWells.find((well) => well.predictionType !== 'Producing');
    }
    return this.tasq
  }

  wellExpandedIcon() {
	  if (this.wellListExpanded) {
		  return 'arrow_drop_down';
	  }
		  return 'arrow_drop_up';
  }

  wellExpandedText() {
	  if (this.wellListExpanded) {
		  return 'Hide wells';
	  }
		  return 'Show wells';
  }

  async showWellsClicked() {
	    await this.sleep(500);
	  this.wellListExpanded = !this.wellListExpanded;
  }

  get padWells() {
	  // @ts-ignore
	  return this.tasq.wells;
  }

  colors = {
	  workflow: 'rgba(123, 65, 216, 1)',
	  'off-target rt': '#ec3114',
	  'off-target': '#ec8114',
	  'no-comms': '#919191',
	  'no comms': '#919191',
	  producing: '#adadad7e',
	  predictions: '#14A86B',
	  prediction: '#14A86B',
	  failure: '#14A86B',
	  'failure prediction': '#14A86B',
	  anomaly: '#ffb01b',
	  'state change': '#ffb01b',
	  setpoint: '#0076FF',
  }

  get getPadTasqStatusBarHtml() {
    if (this.showRelatedWells) {
	  const prediction_type_dict = {};
	  for (let x = 0; x < this.tasq!.wells.length; x++) {
      //   if (this.tasq!.wells[x].PredictionType == undefined) {
      // 	  this.tasq!.wells[x].PredictionType = "Producing"
      //   }

        let prediction_type = this.tasq!.wells[x].predictionType;
        if (this.tasq!.wells[x].completed) {
          prediction_type = 'Producing';
        }
		  if (prediction_type_dict[prediction_type] !== undefined) {
			  prediction_type_dict[prediction_type] += 1;
		  } else {
			  prediction_type_dict[prediction_type] = 1;
		  }
	  }

      let return_html = '';
      let loop_count = 0;
      for (const [key, value] of Object.entries(prediction_type_dict)) {
        let rounded_html = '';
        if ((Object.keys(prediction_type_dict).length - 1) == loop_count) {
        // Add right rounded border
          rounded_html = 'border-top-right-radius: 5px; border-bottom-right-radius: 5px; ';
        }
        if (loop_count == 0) {
        // Add left rounded border
          rounded_html += 'border-top-left-radius: 5px; border-bottom-left-radius: 5px; ';
        }

        // @ts-ignore
        const width = Math.floor(value / this.tasq!.wells.length * 100);

        let bar_color = this.colors[key.toLowerCase()];
        if (!bar_color) {
          bar_color = '#5a6063';
        }
        return_html = `${return_html}<div class="tooltip" style="${rounded_html} width: ${width}%; background: ${bar_color}; height: 100%; float: left; "><span class="tooltiptext">${String(value)} ${key}</span></div>`;
        loop_count += 1;
      }

	  return return_html;
    }

    return '';
  }

  get tasq(): TasqJob|undefined {
    if (this.tasqId == null) {
      return undefined;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get producingTasq(): any|undefined {
    for (let x = 0; x < this.enabledWells.length; x++) {
      if (this.tasqWellName != null && this.enabledWells[x].wellName == this.tasqWellName) {
        return this.enabledWells[x];
      }
    }
    return null;
  }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  fullName() {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get getWells() {
    if (this.showRelatedWells) {
	  if (this.showAllWells) {
		  const responseData: any[] = [];

		  // @ts-ignore
		  for (let r = 0; r < this.tasq.wells.length; r++) {
			  // @ts-ignore
			  if (this.tasq.wells[r].completed) {
				  // @ts-ignore
            const newObject = this.tasq.wells[r];
            newObject.predictionType = 'Producing';
				  // @ts-ignore
				  responseData.push(newObject);
			  } else {
				  // @ts-ignore
				  responseData.push(this.tasq.wells[r]);
			  }
		  }
		  return responseData;
	  }
		  const responseData: any[] = [];
		  // @ts-ignore
		  for (let r = 0; r < this.tasq.wells.length; r++) {
			  // @ts-ignore
			  if (this.tasq.wells[r].predictionType != 'Producing' && !this.tasq.wells[r].completed) {
				  // @ts-ignore
				  responseData.push(this.tasq.wells[r]);
			  }
		  }
      //   console.log(responseData)
		  return responseData;
    }

    return [];
  }

  async created() {
    setTimeout(() => {
      this.showRelatedWells = true;
    }, 1500);
  }

  goToTasqPage(type) {
    if (type == 'producing') {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.wellName || '',
        },
        query: { type: 'producing' },
      });
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
      // assetsModule.setActiveProducingTasq(this.tasq)
    } else {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.id || '',
        },
        query: { type: 'id' },
      });
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    }
  }
}
